import { Image, Button} from 'semantic-ui-react'
import { Link } from "react-router-dom";
import CatIcon from "../cat2.png"
import './NavigationBar.css'

const pageNames = {
  '': "Home",
  about: "About",
  contact: "Contact Me"
}

const NavigationBar = ({currentPage}) => {

  return (<div id="navigationBar">
  <Image className="catIcon" src={CatIcon}/>
  {Object.keys(pageNames).map(page => <Link to={'/' + page}> <Button className="navigationButton" key={page} disabled={page === currentPage} >{pageNames[page]}</Button> </Link>)}
</div>)
}

export default NavigationBar;