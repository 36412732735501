
import 'semantic-ui-css/semantic.min.css'
import '../../App.css';
import { Form, Loader } from 'semantic-ui-react'
import './contactMe.css';
import linkedInIcon from './icons/linkedin.svg'
import { Image } from 'semantic-ui-react'
import {useEffect, useState} from 'react'
import * as EmailValidator from  'email-validator';
import { Icon } from 'semantic-ui-react'
import PageHeader from "../../sharedComponents/PageHeader.js"

const SocialMediaLinks = () => {
  return (<>
   <p className="lessMargin">
      Or you can contact me via email:
      </p>
      <p id="myEmail"> example@jessie-wang.com </p>
      <p>
      Or social media:
     </p>
     <div id="SocialMediaIcons">
     <Image id="linkedInIcon" src={linkedInIcon} href='https://www.linkedin.com/in/jessie-wang-61b049122/' as='a'
size='tiny'></Image>
</div>
</>)
}

const SuccessNotification = ({visible}) => {
  return (<div id="SuccessNotification" className={"notification" +(visible ? '' : ' hidden')}>Message sent.</div>);
}

const FailureNotification = ({visible}) => {
  return (<div id="FailureNotification" className={"notification" +(visible ? '' : ' hidden')}>Failed to send.</div>);
}

const ContactMeMobilePageContent = () => {
  return (
    <div className="columnContainer">
      <div className="column" id="messageColumn">
        <p>
        If you have any questions or comments, please use the form to drop me a message, and I will get back to you soon  <Icon className='HeartIcon' name='heart' />
        </p>
      </div>

      <div className="column" id="formColumn">
      <ContactMeForm/>
      </div>

      <div className="column">
        <SocialMediaLinks/>
      </div>
    </div>
    )

}

const ContactMeDesktopPageContent = () => {
  return (
  <div className="columnContainer">
    <div className="column" id="messageColumn">
      <p>
      If you have any questions or comments, please use the form to drop me a message, and I will get back to you soon  <Icon className='HeartIcon' name='heart' />
      </p>
      <SocialMediaLinks/>
    </div>

    <div className="column" id="formColumn">
      <ContactMeForm/>
    </div>

  </div>)

}

const ContactMeForm = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [message, setMessage] = useState('')
  const [isInvalidEmail, setIsInvalidEmail] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);

  const [showFailureNotification, setShowFailureNotification] = useState(false);
  const onEmailChanged = (newEmail) => {
    setEmail(newEmail)
    setIsInvalidEmail((newEmail && !EmailValidator.validate(newEmail)))
  }
  const invalidEmailError = {
    content: 'Please enter a valid email address',
    pointing: 'above',
  }
  const handleSubmit = () => {
    if (isInvalidEmail) {
      console.log("not sending a message, the email is invalid")
      return;
    }
    setIsSending(true)
    console.log("Sending message.")
    const data = JSON.stringify({
      message: `Name: ${name}\nEmail: ${email}\nPhone: ${phoneNumber}\nMessage:\n${message}\n`,
      senderName: name
    });

    const xhr = new XMLHttpRequest();
    xhr.withCredentials = false;

    xhr.addEventListener("readystatechange", function() {
      if(this.readyState === 4) {
        console.log(this.responseText);
        if (this.status === 200) {
          console.log("success")
          setShowSuccessNotification(true)
          setTimeout(() => { setShowSuccessNotification(false) }, 500)
          setName('');
          setEmail('');
          setPhoneNumber('');
          setMessage('');
          setShowSuccessNotification(true);
        } else {
          setShowFailureNotification(true);
          setTimeout(() => { setShowFailureNotification(false) }, 500)
        }
        setIsSending(false)
      }
    });

    xhr.open("POST", "https://still-meadow-62485.herokuapp.com/send-message");
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.send(data);
  }
  //useEffect(()=> {setShowSuccessNotification(false)}, [showSuccessNotification])

  const buttonContent = isSending ? <span><Loader id="loader" active inline size='mini'/> Sending</span> : 'Send'

  return ( <Form onSubmit={handleSubmit}>

    <Form.Input fluid label='Name' value={name} placeholder='Harry Potter' onChange={(e, {value}) => {setName(value)}} required/>
    <Form.Input fluid label='Email' value={email} placeholder='harry.potter@hogwarts.edu.uk' required onChange={(e, {value}) => {onEmailChanged(value)}} error={isInvalidEmail ? invalidEmailError : false} />
    <Form.Input fluid label='Phone' value={phoneNumber} placeholder='0412 345 678' onChange={(e, {value}) => {setPhoneNumber(value)}} />
    <Form.TextArea label='Message' value={message} placeholder='Hi Jessie...' required onChange={(e, {value}) => {setMessage(value)}} />
    <div id="sendButtonContainer">
    <Form.Button id="sendButton" disabled={isSending}>{buttonContent}</Form.Button>
    </div>
    <SuccessNotification visible={showSuccessNotification} />
    <FailureNotification visible={showFailureNotification} />
  </Form>)

}

function ContactMe() {

  const [isMobileSized, setIsMobileSized] = useState(false);
  useEffect(() => {
    const mediaWatcher = window.matchMedia("screen and (max-width: 700px)")
    setIsMobileSized(mediaWatcher.matches);
    function updateMediaSize(e) {
      setIsMobileSized(e.matches);
    }

    if(mediaWatcher.addEventListener) {
      mediaWatcher.addEventListener('change', updateMediaSize)
      return function cleanup() {
        mediaWatcher.removeEventListener('change', updateMediaSize)
      }
    } else {
      // for safari
      mediaWatcher.addListener(updateMediaSize)
      return function cleanup() {
        mediaWatcher.removeListener(updateMediaSize)
      }
    }

  }, [])

  return (
    <PageHeader id="contactMePage" title="Contact Me" currentPage='contact' pageContent={isMobileSized ? <ContactMeMobilePageContent/> : <ContactMeDesktopPageContent/>}/>
  )
}

export default ContactMe;