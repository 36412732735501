import React, { Component } from 'react'
import { Menu, Button } from 'semantic-ui-react'
import {
  Link
} from "react-router-dom";
import "./MainMenu.css"
export default class MainMenu extends Component {
  render() {

    return (
      <Menu secondary id="mainMenu">
        <Link  to="/"><Button disabled className="navigationButton">Home</Button></Link>
        <Link  to="/about"><Button className="navigationButton">About</Button></Link>
        <Link  to="/contact"><Button className="navigationButton">Contact Me</Button></Link>
      </Menu>
    )
  }
}