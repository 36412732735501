import React from "react";
import Home from "./pages/home/Home"
import About from "./pages/about/About"
import ContactMe from "./pages/contact/ContactMe"
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

export default function App() {
  return (
    <div id="App" className="App">
   <Router>
        <Switch>
          <Route path="/about">
            <About></About>
          </Route>
          <Route path="/contact">
            <ContactMe></ContactMe>
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
    </Router>
    </div>
  );
}
