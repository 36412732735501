
import 'semantic-ui-css/semantic.min.css'
import '../../App.css';
import PageHeader from '../../sharedComponents/PageHeader';
import { Image } from 'semantic-ui-react'
import JessieImage from './jessie-500.png'
import CatsImage from './cat.svg'
import './About.css'
import { PieChart } from 'react-minimal-pie-chart';
import { useState, useEffect } from 'react';



const MultisizedComponent = ({mobileContent, desktopContent}) => {
  const [isMobileSized, setIsMobileSized] = useState(false);
  useEffect(() => {
    const mediaWatcher = window.matchMedia("screen and (max-width: 700px)")
    setIsMobileSized(mediaWatcher.matches);
    function updateMediaSize(e) {
      setIsMobileSized(e.matches);
    }

    if(mediaWatcher.addEventListener) {
      mediaWatcher.addEventListener('change', updateMediaSize)
      return function cleanup() {
        mediaWatcher.removeEventListener('change', updateMediaSize)
      }
    } else {
      // for safari
      mediaWatcher.addListener(updateMediaSize)
      return function cleanup() {
        mediaWatcher.removeListener(updateMediaSize)
      }
    }

  }, [])

  if (isMobileSized) return mobileContent;
  return desktopContent

}

const PieSection = () => {
  const desktopContent = (
  <div id="pieSection">
    <div className='sectionContainer'>
    <div className="column labels" >
      <ul>
        <li id="mathematician">
           Mathematician
        </li>
        <li id="programmer">
           Programmer
        </li>
      </ul>
    </div>
    <div className="column">
    <PieChart className="pieChart"
data={[
  { title: 'Mathematician', value: 35, color: '#365bb7' },
  { title: 'Programmer', value: 20, color: '#e36725' },
  { title: 'Data Engineer', value: 10, color: '#AAAAAA' },
  { title: 'Data Scientist', value: 35, color: '#fdb50b' },

]}
/>
    </div>
    <div className="column labels last">
    <ul>
        <li id='dataScientist'>
           Data Scientist
        </li>
        <li id='dataEngineer'>
           Data Engineer
        </li>
      </ul>
    </div>
</div>
</div>
)


const mobileContent = (<div> <div id="pieSection">
<div className='sectionContainer'>

<div className="column">
<PieChart className="pieChart"
data={[
{ title: 'Mathematician', value: 35, color: '#365bb7' },
{ title: 'Programmer', value: 20, color: '#e36725' },
{ title: 'Data Engineer', value: 10, color: '#AAAAAA' },
{ title: 'Data Scientist', value: 35, color: '#fdb50b' },

]}
/>
</div>
<div style={{display:"flex"}}>
<div className="column labels" >
  <ul>
    <li id="mathematician">
       Mathematician
    </li>
    <li id="programmer">
       Programmer
    </li>
  </ul>
</div>
<div className="column labels last">
<ul>
    <li id='dataScientist'>
       Data Scientist
    </li>
    <li id='dataEngineer'>
       Data Engineer
    </li>
  </ul>
</div>


</div>
</div>
</div></div>)

return MultisizedComponent({mobileContent, desktopContent})

}
function About() {

  const pictureWithDescriptionContainer = (
  <div id="pictureWithDescriptionContainer">
    <div className="sectionContainer">
    <div id="imageContainer">
      <Image src={JessieImage} id="pictureOfJessie"></Image>
    </div>
    <div id="description">
      <h3>A data scientist based in Melbourne Australia.</h3>
      <p>I'm passionate about optimisation, operational research, ML and related areas. I have worked in both academia and industry. Previously, I worked as an assistant researcher at the University of Melbourne. During that time, I collaborated with the local council to analyse bottlenecks of transportation in the CBD. I'm currently working at ThoughtWorks Australia as a data consultant, where I have consulted clients in the airline, FMCG, and accounting industries to better manage and utilise their data for increasing business insight.
  </p>
    </div>
    </div>
  </div>)



  const randomFactSection = (
    <div id="randomFactSection">
      <div className="sectionContainer">
      <Image size="medium" className="catImage" src={CatsImage} style={{transform: "scaleX(-1)"}}></Image>
        <div id="randomFactsText">
        <h3>Random facts about me:</h3>
        <ul>
          <li>I love cooking (and eating!)</li>
          <li>I enjoy knitting</li>
          <li>I drink at least 4 litres of water a day</li>
          <li>I was a dealer at a casino</li>
          <li>I've never read/watched Harry Potter</li>
          <li>I'm addicted to claw machines</li>
        </ul>
        </div>

        <Image size="medium" className="catImage" src={CatsImage} ></Image>
      </div>
    </div>
  )

  const conferencesSection = (
    <div id="conferencesSection">
      <div className="sectionContainer">

      <h3>Previous conference talks</h3>
      <p>
      A study of loading zone usage in Melbourne central city areas
                            <span><hr className="longDash"/> The 24th International Conference of Hong Kong Society of Transportation studies</span>
                             </p>
                             <p>
Making Use of All Your Data, Labelled or Otherwise

                             <span><hr className="longDash"/> ThoughtWorks 2020 XConf </span>

      </p>
      </div>
    </div>
  )

  const pageContent = (
    <div>
      {pictureWithDescriptionContainer}
      <PieSection/>
      {randomFactSection}
      {conferencesSection}
    </div>
  )

  return (
      <PageHeader id="aboutMePage" currentPage='about' title="About Me" pageContent={pageContent} />
  )

}

export default About;