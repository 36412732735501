
import NavigationBar from './NavigationBar.js'
import spaceImage from './space-image.jpg';
import { Image } from 'semantic-ui-react'

const PageHeader= ({title, pageContent, currentPage, id}) => {
  return (
    <div className="page" id={id}>
    <header>
      <h2>{title}</h2>
      <NavigationBar currentPage={currentPage} />
    </header>
    <main>

    <Image id="contactMeHeaderImage" src={spaceImage}></Image>
    <br></br>
    {pageContent}
    </main>
    </div>
  )
}

export default PageHeader