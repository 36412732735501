
import 'semantic-ui-css/semantic.min.css'
import MainMenu from './menu/MainMenu';
import '../../App.css';
import './Home.css'
import './BlogPostTile.css'
import SampleImage from "./cats.jpg"
import { Image } from 'semantic-ui-react'

import { Icon } from 'semantic-ui-react'

const BlogPostTile = ({date, title, summary, image }) => {
  return (
    <div className="blogPostTile">
      <div className="centeredChunk">
    <div className="date">Jan. 12 2021</div>
    <div className="textBlock">
    <h3>Lorem ipsum dolor sit amet</h3>
    <p className="summary">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vitae rhoncus massa. In tincidunt orci et ex ornare posuere. Suspendisse ligula tellus, sollicitudin id imperdiet vitae, sollicitudin id odio. Morbi tempor lobortis tortor, in auctor dui feugiat ultrices. Duis congue, nulla id gravida pretium, turpis urna commodo orci, et eleifend arcu libero vitae ligula. Praesent ultricies in dui sed convallis. Maecenas suscipit neque ut risus aliquam sollicitudin. Nullam nec neque nulla. In a pretium dolor. Proin sed diam vel neque porta euismod ac quis magna.</p>
    </div>
    <Image src={SampleImage}/>
    </div>
    </div>
  )

}

const showBlogPosts = false;
function Home() {
  return (
    <div id="homePage">
      <div className="header">
        <h1>Jessie Wang's World</h1>
        {/* <h3 className="subtitle">Smile and never give up <Icon className='rotate-3d' name='heart' /></h3> */}
        <h2 className="subtitle">Smile and never give up <Icon className='HeartIcon' name='heart' /></h2>
        <MainMenu></MainMenu>
      </div>


      {showBlogPosts &&
      <div id="BlogPostTiles">
        <BlogPostTile/>
        <BlogPostTile/>
        <BlogPostTile/>
        <BlogPostTile/>
        <BlogPostTile/>
        <BlogPostTile/>
      </div>
}
    </div>
  );
}

export default Home;
